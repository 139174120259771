














































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Statues } from '@/shared/interfaces/Statues';
import { BTooltip } from 'bootstrap-vue';

@Component({
  components: { SygniRectButton, SygniRoundedButton, BTooltip }
})
export default class MarketplaceModule extends Vue {
  get activeProduct() {
    return this.$store.getters['investors/newProduct']
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get annexingProducts() {
    return this.$store.getters['investors/annexingProducts']
  }

  get mode() {
    return this.$route.params?.id ? 'edit' : 'add'
  }

  get isLlc() {
    return this.activeUserData?.equityType !== 'JOINT_STOCK_SHARES'
  }

  get isProductSummary() {
    return this.$route?.name === 'productSummaryForLegalEntity' || this.$route?.name === 'productSummaryForInvestmentClient' ? true : false
  }

  get autentiStatuses() {
    return this.$store.getters['templates/getAutentiDictionaries']?.statuses
  }

  get autentiData() {
    return this.$store.getters['investors/getAutentiStatusData']
  }

  autentiStatusClass(item: any) {
    const autentiStatus = item?.eSigned ? 'COMPLETED' : item?.autentiStatus ? item?.autentiStatus?.toUpperCase() : 'NONE'

    switch(autentiStatus) {
      case('NEW'): {
        return 'black'
      }
      case('UNKNOWN'):
      case('WITHDRAWING'): {
        return 'disabled';
      }
      case('COMPLETED'): {
        return 'primary';
      }
      case('PROCESSING'):
      case('DRAFT'): {
        return 'success';
      }
      case('ERROR'):
      case('WITHDRAWN'):
      case('REJECTED'): {
        return 'danger';
      }
    }
    return 'black';
  }

  autentiStatusText(item: any): string {
    const status = item?.eSigned ? 'COMPLETED' : item?.autentiStatus
    const autentiStatus = this.autentiStatuses?.find((el: any) => el?.value?.toUpperCase() === status?.toUpperCase())

    return autentiStatus?.label || item?.autentiStatus || ''
  }

  statusClass(status: Statues) {
    switch (status?.toUpperCase()) {
      case (Statues.new): {
        return 'black';
      }
      case (Statues.active): {
        return 'primary';
      }
      case (Statues.invited): {
        return 'success';
      }
      case (Statues.awaiting): {
        return 'success';
      }
      case (Statues.pending): {
        return 'black';
      }
      case (Statues.rejected): {
        return 'danger';
      }
      case (Statues.closed): {
        return 'disabled';
      }
      case (Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch (status?.toUpperCase()) {
      case (Statues.new): {
        return 'New';
      }
      case (Statues.active): {
        return 'Active';
      }
      case (Statues.invited): {
        return 'Awaiting';
      }
      case (Statues.awaiting): {
        return 'Awaiting';
      }
      case (Statues.pending): {
        return 'Pending';
      }
      case (Statues.rejected): {
        return 'Rejected';
      }
      case (Statues.closed): {
        return 'Closed';
      }
      case (Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  getProductLink(id: string) {
    return this.$route.path.includes('company') ? `/company/fundraising/product/summary/${id}/for-legal-entity` : `/fund/fundraising/product/summary/${id}/for-legal-entity`
  }

  goBack() {
    if (this.$route.name === 'addNewProductStepTwo') {
      if (this.activeProduct?.subscriptionId) {
        this.$router.push({ name: 'addNewProductStepOne', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/new/step-one`, params: { subscriptionId: this.activeProduct.subscriptionId, productType: this.isLlc ? 'LLC_SHARES' : 'JOINT_STOCK_SHARES' } })
      } else {
        this.$router.go(-1)
      }
    } else if (this.$route.name === 'addNewProductStepOne') {
      if (this.activeProduct?.subscriptionId) {
        this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising?page=subscriptions` })
      } else {
        if (this.activeProduct?.investmentClientId && this.activeProduct?.investorLegalEntityId) {
          this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising` })
        } else {
          this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising?page=products` })
        }
      }
    } else {
      this.$router.go(-1)
    }
  }
}
